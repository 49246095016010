import React, { useState, useEffect } from 'react';

function Art() {
  const [latestPost, setLatestPost] = useState(null);

  useEffect(() => {
    // This is a dummy function simulating an API call.
    const fetchLatestPost = async () => {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve("Tanta Arte! wow!");
        }, 1000);
      });
    };

    fetchLatestPost().then(data => {
      setLatestPost(data);
    });
  }, []);

  if (!latestPost) return <div>Loading...</div>;

  return (
    <div>
      {latestPost}
    </div>
  );
}

export default Art;
