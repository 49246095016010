import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';
import { getStorage } from 'firebase/storage';
import firebaseConfig from './firebaseConfig';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigLocal = {
  apiKey: "AIzaSyDr2dl7uLY-Mf8Z1wRKon5FOIi-3jHvLRU",
  authDomain: "il-paring-knife.firebaseapp.com",
  projectId: "il-paring-knife",
  storageBucket: "il-paring-knife.appspot.com",
  messagingSenderId: "16102831462",
  appId: "1:16102831462:web:eff26f2256b8d5ded304a7",
  measurementId: "G-49HN8QDL5B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firestore
const db = getFirestore(app);

// Initialize Storage
// const storage = getStorage(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

export {db}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
