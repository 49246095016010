import React, { useEffect, useState } from 'react';
import { useLocation, Routes, Route, Link } from 'react-router-dom';
import FoodJournal from './pages/FoodJournal';
import About from './pages/About'; 
import Art from './pages/Art';
import DIY from './pages/DIY';
import paringKnife from './assets/paringknife-logo.png';
import brownIncenseBackground from './assets/incense-background-resized.jpg';
import copperOlieraBackground from './assets/copper-oliera-background-resized.jpg';
import lavendarCloth from './assets/lavander-cloth-resized.jpg';
import devi from './assets/devi-argento-resized.jpg';

function InnerApp() {
  const location = useLocation();
  const [currentBackground, setCurrentBackground] = useState(lavendarCloth);

  useEffect(() => {
    let newBackground;
    switch(location.pathname) {
      case '/about':
        newBackground = lavendarCloth;
        break;
      case '/food':
        newBackground = copperOlieraBackground;
        break;
      case '/art':
        newBackground = devi;
        break;
      case '/diy':
        newBackground = brownIncenseBackground;
        break;
      default:
        newBackground = lavendarCloth;
        break;
    }

    setCurrentBackground(newBackground);

  }, [location]);

  return (
    <div className="App">
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,    
        backgroundImage: `linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,0.6)), url(${currentBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: 0,
      }} />
      <div style={{ position: 'relative', zIndex: 1 }}>
        <h1>Il Paring Knife</h1>
        <img src={paringKnife} alt="Paring Knife Logo" style={{ width: '80%', maxWidth: '200px' }} />
        <div className="sections">
          <Link to="/about">About</Link>  
          <Link to="/diy">D.I.Y</Link>  
          <Link to="/food">Food</Link>  
          <Link to="/art">Art</Link>
        </div>
        <svg width="300" height="10" className="hand-drawn-line">
          <path d="M10 5 Q140 15 150 5 T290 5" stroke="black" strokeWidth="2" fill="none" />
        </svg>
        <Routes>
          <Route path="*" element={<div>Ciao</div>} />
          <Route path="/about" element={<About />} />  
          <Route path="/diy" element={<DIY />} />  
          <Route path="/food" element={<FoodJournal />} />
          <Route path="/art" element={<Art />} />
        </Routes>
      </div>
    </div>
  );
}

export default InnerApp;
