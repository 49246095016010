import React from 'react';

function About() {
  return (
    <div class="container">
      <p class="content">
        DIY where I make things with my hands and post pictures of those things
      </p>
    </div>
  );
}

export default About;
